import styled from '@emotion/styled';

import { getLangText } from 'utils/ui';

const Ribbon = styled.div`
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  
  background-color: #BD1550;
  color: #fff;
  font-size: 0.875em;

  &.color_brand {
    background-color: var(--brand);
  }

  &.color_muted {
    background-color: var(--bs-gray);
    font-weight: 300;
    font-size: 0.875em;
  }

  &.color_blue {
    background-color: var(--bs-blue);
  }

  &.color_red {
    background-color: var(--bs-danger);
  }

  &.color_yellow {
    background-color: var(--bs-warning);
    color: var(--bs-gray-dark);
  }

  &.color_green {
    background-color: var(--bs-success);
  }
`;


export default function RibbonAnnotation({
  annotation,
}) {
  return (
    <Ribbon className={`color_${annotation.color}`}>
      {getLangText(annotation.text)}
    </Ribbon>
  );
}
